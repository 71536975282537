import React, { Fragment, Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Container, Card, CardHeader, CardBody, CardFooter, Row, Col, UncontrolledTooltip, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Dropdown, DropdownItem, Button, Input, Select, Option, Divider } from 'muicss/react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import FileSaver from 'file-saver';
import { Line, Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import ExportGraph from './exportGraph';
import { CloudWatchLogsClient, CreateLogGroupCommand, CreateLogStreamCommand, PutLogEventsCommand, DescribeLogStreamsCommand } from "@aws-sdk/client-cloudwatch-logs";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
const cookies = new Cookies();
const moment = require('moment');
const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

//const screeningScores = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'];

export default class PatientProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: props.match.params.pid,
            role: localStorage.getItem('role'),
            user: null,
            patient: { fields: {} },
            history: { fields: {} },
            vitals: { fields: {} },
            screening: { fields: {} },
            footPointsHistory: {},
            colateralPoints1: null,
            colateralPoints2: null,
            colateralPoints3: null,
            referrals: [],
            pageLoading: true,
            activeTab: 'patient',
            alerts: [],
            pointFilter: 7,
            historyFoot: 'Right',
            historyPoint: 1,
            historyRange: 7,
            historyData: {},
            activePoints: {
                right: [],
                left: []
            },
            customFilter: 0,
            isRetest1: false,
            isRestest2: false,
            isRetest3: false,
            threshold: 30,
            endDate: moment().format('YYYY-MM-DD'),
            modalOpen: false,
        }
        this.loadUser();
        this.loadPatient();
        this.loadReferrals();
        this.loadColateral();
        this.loadAlerts();
        this.loadTempHistory = this.loadTempHistory.bind(this);
        this.loadActiveFootpoints = this.loadActiveFootpoints.bind(this);
        this.loadActiveFootpoints(props.match.params.pid, true);
        this.handlePointFilter = this.handlePointFilter.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateHistory = this.updateHistory.bind(this);
        this.getBarGraphLabel = this.getBarGraphLabel.bind(this);
        this.downloadRecord = this.downloadRecord.bind(this);
        this.activatePatient = this.activatePatient.bind(this);
        this.deactivatePatient = this.deactivatePatient.bind(this);
        this.deletePatient = this.deletePatient.bind(this);
        this.toggleModal = this.toggleModal.bind(this)
        this.writeLogs = this.writeLogs.bind(this)
    }

    handleInputChange(event) {
        let name = event.target.name;
        this.setState({
            [name]: event.target.value
        })
    }

    updateHistory(event) {
        let name = event.target.name;
        this.setState({
            [name]: event.target.value
        }, this.loadTempHistory);
    }

    loadUser() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/isCare/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let user = response.data;
            this.setState({
                user: user
            });
        }).catch(error => {
            this.setState({ alertShow: true, alertMessage: error.response.data });
            console.log(error);
        })
    }

    loadPatient() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            const patient = response.data[0];
            this.writeLogs(response.data[0])
            this.setState({
                patient: patient,
            }, this.loadPatientHistory);
        }).catch(error => {
            console.log(error);
        })
    }

    async writeLogs(patient) {
        const logKey = localStorage.getItem('logKey');
        const client = new CloudWatchLogsClient({region: 'us-east-1',   credentials: fromCognitoIdentityPool({
            clientConfig: { region: 'us-east-1' },
            identityPoolId: 'us-east-1:671651f5-c5ab-497b-8966-5baae2389b1a',
          })})
        const logGroupName = 'daps_access_logs'
        const logStreamName = `access_logs_${logKey}`
        const logGroupCommand = new CreateLogGroupCommand({logGroupName: logGroupName})
        const logStreamCommand = new CreateLogStreamCommand({logGroupName: logGroupName, logStreamName: logStreamName})
        try {
            const createLogGroup = await client.send(logGroupCommand)
        } catch (error) {
            if (!error.name === 'ResourceAlreadyExistsException') {
                console.log('log group error', error)
            }
        } finally {
            try {
                const createLogStream = await client.send(logStreamCommand)
            } catch (error) {
                if (!error.name === 'ResourceAlreadyExistsException') {
                    console.log('log stream error', error)
                }
            } finally {
                try  {
                    const describeLogStreams = new DescribeLogStreamsCommand({logGroupName: logGroupName, logStreamNamePrefix: logStreamName, limit: 1})
                    const currentStreams = await client.send(describeLogStreams)
                    const sequenceToken = currentStreams.logStreams[0].uploadSequenceToken
                    const logEventCommand = new PutLogEventsCommand({logGroupName: logGroupName, logStreamName: logStreamName, logEvents: [{message: `user ${localStorage.getItem('user_name')} has accessed patient ${patient.fields.FirstName} ${patient.fields.LastName}: ${patient.fields.PatientId} on ${window.location.href}`, timestamp: Date.now()}], sequenceToken: sequenceToken})
                    const data = await client.send(logEventCommand)
                } catch (error) {
                    console.log('log event error', error)
                }
            }
        }
    }

    loadPatientHistory() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/history/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                history: response.data[0],
            }, this.loadPatientVitals);
        }).catch(error => {
            console.log(error);
        })
    }

    loadPatientVitals() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/vitals/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                vitals: response.data[0],
            }, this.loadPatientScreening);
        }).catch(error => {
            console.log(error);
        })
    }

    loadAlerts() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/temp/alertsPatient/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                alerts: response.data.alert,
            }
            );
        }).catch(error => {
            console.log(error);
        })
    }

    loadPatientScreening() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/screening/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                screening: response.data[0],
                pageLoading: false,
            });
        }).catch(error => {
            console.log(error);
        })
    }

    loadReferrals() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/referral/${this.state.pid}/?count=5`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            // console.log(response);
            this.setState({
                referrals: response.data,
            });
        }).catch(error => {
            console.log(error);
        })
    }

    loadColateral() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/temperature/temp/temperaturePairs/${this.state.pid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            axios({
                url: `${base}/api/temperature/temp/pointsBase/${this.state.pid}/`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
            }).then(response2 => {
                let pairingsList = response.data.pairings
                // console.log("Hello THere ", pairingsList)
                var i;
                var k = 0;
                for (i = 0; i < pairingsList.length; i++) {
                    let baselines = response2.data;
                    let pairings = pairingsList[i];
                    let startPoint = Object.keys(pairings)[0];
                    if (Object.values(pairings[startPoint].left).length > 1) {
                        for (let j = 0; j < Object.values(pairings[startPoint].left).length; j++) {
                            let containsRetest = false;
                            let ldata = [];
                            let rdata = [];
                            let lbgc = [];
                            let rbgc = [];
                            let labels = [];
                            let date = null;
                            for (let point in pairings) {
                                let idx = parseInt(point, 10);
                                let left = pairings[point].left;
                                let right = pairings[point].right;
                                let missing = pairings[point].missing;
                                let baselineLeft = baselines[idx + 6];
                                let baselineRight = baselines[idx];
                                let isRetestLeft = Object.values(left)[j] ? Object.values(left)[j].IsRetest : false;
                                let isRetestRight = Object.values(right)[j] ? Object.values(right)[j].IsRetest : false;
                                let pointLeft = Object.values(left)[j] ? Object.values(left)[j].Reading : baselineRight;
                                let pointRight = Object.values(right)[j] ? Object.values(right)[j].Reading : baselineLeft;
                                date = Object.values(left)[j] ? moment(Object.values(left)[j].LocalTimestamp.split('T')[0]).format('MM/DD') : null;
                                if (date === null) {
                                    date = Object.values(right)[j] ? moment(Object.values(right)[j].LocalTimestamp.split('T')[0]).format('MM/DD') : null;
                                }
                                let diff = Math.abs(pointLeft - pointRight);
                                // Account for missing points; compare to previous day instead
                                let previousPairings = pairingsList[Math.min(i + 1, pairingsList.length - 1)];
                                if (missing === "right") {
                                    // console.log('right missing, using past left')
                                    let pastVal = previousPairings[point] ? previousPairings[point].left : {};
                                    let pastPoint = Object.values(pastVal)[j] ? Object.values(pastVal)[j].Reading : baselineRight;
                                    diff = Math.abs(pointLeft - pastPoint);
                                } else if (missing === "left") {
                                    // console.log('left missing, using past right')
                                    let pastVal = previousPairings[point] ? previousPairings[point].right : {};
                                    let pastPoint = Object.values(pastVal)[j] ? Object.values(pastVal)[j].Reading : baselineLeft;
                                    diff = Math.abs(pointRight - pastPoint);
                                }
                                let leftColor = '#22BF2A';
                                let rightColor = '#77EA7D';
                                // If a footpoint is missing, we want the color to be determined by an 8 degree difference
                                // In comparison to its previous data
                                if (missing) {
                                    if (diff >= 8) {
                                        leftColor = '#F72520';
                                        rightColor = '#F9847C';
                                    } else if (diff > 4) {
                                        // As an alternative, set in yellow if the difference is more than 4 degrees; could be the beginning of something bad
                                        leftColor = '#F7A000'
                                        rightColor = '#FFCC00';
                                    }
                                } else {
                                    // If both foot points are present, do a contralateral comparison & a previous day comparison
                                    let pastLeft = previousPairings[point] ? previousPairings[point].left : {};
                                    let pastRight = previousPairings[point] ? previousPairings[point].right : {};
                                    let pastRightPoint = Object.values(pastRight)[i] ? Object.values(pastRight)[i].Reading : baselineLeft;
                                    let pastLeftPoint = Object.values(pastLeft)[i] ? Object.values(pastLeft)[i].Reading : baselineRight;
                                    let pastDiff = Math.abs(pastRightPoint - pastLeftPoint);
                                    // First, contralateral difference should be 4 or more. This indicates a possible warning condition
                                    if (diff >= 4) {
                                        leftColor = '#F7A000'
                                        rightColor = '#FFCC00';
                                        // Check the past diff data for whether to display in red; also greater than 4 degrees
                                        if (pastDiff >= 4) {
                                            leftColor = '#F72520';
                                            rightColor = '#F9847C';
                                        }
                                    }
                                }
                                // if (pointLeft >= 90) leftColor = '#F72520';
                                // if (pointRight >= 90) rightColor = '#F9847C';
                                lbgc.push(leftColor);
                                rbgc.push(rightColor);
                                ldata.push(pointLeft);
                                rdata.push(pointRight);
                                if (!containsRetest) {
                                    containsRetest = isRetestLeft || isRetestRight
                                }
                                switch (point) {
                                    case '1':
                                        labels.push('Big Toe')
                                        break;
                                    case '2':
                                        labels.push('First Metatarsal')
                                        break;
                                    case '3':
                                        labels.push('Second-Fourth Metatarsals')
                                        break;
                                    case '4':
                                        labels.push('Fifth Metatarsal')
                                        break;
                                    case '5':
                                        labels.push('Medial Plantar')
                                        break;
                                    case '6':
                                        labels.push('Heel')
                                        break;
                                    default:
                                        console.log('Undefined')
                                }
                            }
                            let data = {
                                labels: labels,
                                date: date === null ? "Date Not Found" : date,
                                datasets: [
                                    {
                                        label: 'Left Foot',
                                        fill: false,
                                        lineTension: 0.1,
                                        backgroundColor: lbgc,
                                        borderColor: lbgc,
                                        pointBackgroundColor: 'white',
                                        pointBorderColor: lbgc,
                                        data: ldata,
                                        date: date,
                                    },
                                    {
                                        label: 'Right Foot',
                                        fill: false,
                                        lineTension: 0.1,
                                        backgroundColor: rbgc,
                                        borderColor: rbgc,
                                        pointBackgroundColor: 'white',
                                        pointBorderColor: rbgc,
                                        data: rdata,
                                    },
                                ]
                            }
                            // console.log('if loop', k)
                            this.setState({
                                ["isRetest" + (k + 1)]: containsRetest,
                                ["colateralPoints" + (k + 1)]: data,
                            }, this.loadFootPoints);
                            data = {};
                            k++;
                        }
                    } else {
                        let containsRetest = false;
                        let ldata = [];
                        let rdata = [];
                        let lbgc = [];
                        let rbgc = [];
                        let labels = [];
                        let date = null;
                        for (let point in pairings) {
                            let idx = parseInt(point, 10);
                            let left = pairings[point].left;
                            let right = pairings[point].right;
                            let missing = pairings[point].missing;
                            let baselineLeft = baselines[idx + 6];
                            let baselineRight = baselines[idx];
                            let isRetestLeft = Object.values(left).length !== 0 ? Object.values(left)[0].IsRetest : false;
                            let isRetestRight = Object.values(right).length !== 0 ? Object.values(right)[0].IsRetest : false;
                            let pointLeft = Object.values(left).length !== 0 ? Object.values(left)[0].Reading : baselineRight;
                            let pointRight = Object.values(right).length !== 0 ? Object.values(right)[0].Reading : baselineLeft;
                            date = Object.values(left).length !== 0 ? moment(Object.values(left)[0].LocalTimestamp.split('T')[0]).format('MM/DD') : null;
                            if (date === null) {
                                date = Object.values(right).length !== 0 ? moment(Object.values(right)[0].LocalTimestamp.split('T')[0]).format('MM/DD') : null;
                            }
                            let diff = Math.abs(pointLeft - pointRight);
                            // Account for missing points; compare to previous day instead
                            let previousPairings = pairingsList[Math.min(i + 1, pairingsList.length - 1)];
                            if (missing === "right" && previousPairings.length > 1) {
                                // console.log('right missing, using past left')
                                let pastVal = previousPairings[point] ? previousPairings[point].left : {};
                                let pastPoint = Object.values(pastVal)[0] ? Object.values(pastVal)[0].Reading : baselineRight;
                                diff = Math.abs(pointLeft - pastPoint);
                            } else if (missing === "left" && previousPairings.length > 1) {
                                // console.log('left missing, using past right')
                                let pastVal = previousPairings[point] ? previousPairings[point].right : {};
                                let pastPoint = Object.values(pastVal)[0] ? Object.values(pastVal)[0].Reading : baselineLeft;
                                diff = Math.abs(pointRight - pastPoint);
                            }
                            // console.log(diff);
                            let leftColor = '#22BF2A';
                            let rightColor = '#77EA7D';
                            // If a footpoint is missing, we want the color to be determined by an 8 degree difference
                            // In comparison to its previous data
                            if (missing) {
                                if (diff >= 8) {
                                    leftColor = '#F72520';
                                    rightColor = '#F9847C';
                                } else if (diff > 4) {
                                    // As an alternative, set in yellow if the difference is more than 4 degrees; could be the beginning of something bad
                                    leftColor = '#F7A000'
                                    rightColor = '#FFCC00';
                                }
                            } else {
                                // If both foot points are present, do a contralateral comparison & a previous day comparison
                                let pastLeft = previousPairings[point] ? previousPairings[point].left : {};
                                let pastRight = previousPairings[point] ? previousPairings[point].right : {};
                                let pastRightPoint = Object.values(pastRight)[0] ? Object.values(pastRight)[0].Reading : baselineLeft;
                                let pastLeftPoint = Object.values(pastLeft)[0] !== 0 ? Object.values(pastLeft)[0].Reading : baselineRight;
                                let pastDiff = Math.abs(pastRightPoint - pastLeftPoint);
                                // First, contralateral difference should be 4 or more. This indicates a possible warning condition
                                if (diff >= 4) {
                                    leftColor = '#F7A000'
                                    rightColor = '#FFCC00';
                                    // Check the past diff data for whether to display in red; also greater than 4 degrees
                                    if (pastDiff >= 4) {
                                        leftColor = '#F72520';
                                        rightColor = '#F9847C';
                                    }
                                }
                            }
                            // if (pointLeft >= 90) leftColor = '#F72520';
                            // if (pointRight >= 90) rightColor = '#F9847C';
                            lbgc.push(leftColor);
                            rbgc.push(rightColor);
                            ldata.push(pointLeft);
                            rdata.push(pointRight);
                            if (!containsRetest) {
                                containsRetest = isRetestLeft || isRetestRight
                            }
                            switch (point) {
                                case '1':
                                    labels.push('Big Toe')
                                    break;
                                case '2':
                                    labels.push('First Metatarsal')
                                    break;
                                case '3':
                                    labels.push('Second-Fourth Metatarsals')
                                    break;
                                case '4':
                                    labels.push('Fifth Metatarsal')
                                    break;
                                case '5':
                                    labels.push('Medial Plantar')
                                    break;
                                case '6':
                                    labels.push('Heel')
                                    break;
                                default:
                                    console.log('Undefined')
                            }
                        }
                        let data = {
                            labels: labels,
                            date: date === null ? "Date Not Found" : date,
                            datasets: [
                                {
                                    label: 'Left Foot',
                                    fill: false,
                                    lineTension: 0.1,
                                    backgroundColor: lbgc,
                                    borderColor: lbgc,
                                    pointBackgroundColor: 'white',
                                    pointBorderColor: lbgc,
                                    data: ldata,
                                    date: date,
                                },
                                {
                                    label: 'Right Foot',
                                    fill: false,
                                    lineTension: 0.1,
                                    backgroundColor: rbgc,
                                    borderColor: rbgc,
                                    pointBackgroundColor: 'white',
                                    pointBorderColor: rbgc,
                                    data: rdata,
                                },
                            ]
                        }
                        // console.log('else', k);
                        this.setState({
                            ["isRetest" + (k + 1)]: containsRetest,
                            ["colateralPoints" + (k + 1)]: data,
                        },
                            this.loadFootPoints);
                        data = {};
                        k++;
                    }
                }
            })
        }).catch(error => {
            console.log(error);
        })
    }

    loadFootPoints() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/temperature/temp/pointDifference/${this.state.pid}/?point=${this.state.pointFilter}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let points = [];
            let ubound = [];
            let lbound = [];
            let labels = [];
            let bgc = [];
            let upper = 4;
            let lower = -4
            let differences = response.data

            differences.forEach(set => {
                let length = 0;
                for (let key in set) {
                    let currLength = Object.keys(set[key]).length;
                    length = Math.max(currLength, length);
                }
                for (let j = 0; j < length; j++) {
                    for (let i = 1; i < 7; i++) {
                        let label = moment(set.date.split('T')[0]).format("MM/DD/YYYY") + " - ";
                        switch (i) {
                            case 1:
                                label += 'Big Toe'
                                break;
                            case 2:
                                label += 'First Metatarsal'
                                break;
                            case 3:
                                label += 'Second-Fourth Metatarsals'
                                break;
                            case 4:
                                label += 'Fifth Metatarsal'
                                break;
                            case 5:
                                label += 'Medial Plantar'
                                break;
                            case 6:
                                label += ('Heel')
                                break;
                            default:
                                console.log('Undefined')
                        }
                        if (set[i][j] || set[i][j] === 0.0) {
                            labels.push(label);
                            points.push(set[i][j]);
                            ubound.push(upper);
                            lbound.push(lower);
                            let diff = Math.abs(set[i][j]);
                            if (diff >= 4) {
                                bgc.push('#F72520');
                            } else if (diff >= 3.5) {
                                bgc.push('#F7A000');
                            } else {
                                bgc.push('#22BF2A');
                            }
                        }
                    }
                }
            });
            labels.push('extend');
            ubound.push(upper);
            lbound.push(lower);
            let data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Delta',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: 'lightyellow',
                        borderColor: 'lightyellow',
                        borderWidth: 1,
                        pointBackgroundColor: bgc,
                        pointBorderColor: 'black',
                        pointBorderWidth: 1,
                        data: points,
                    },
                    {
                        label: 'Upper Bound',
                        fill: '+1',
                        lineTension: 0.1,
                        backgroundColor: 'rbga(211, 211, 211, 0.1)',
                        borderColor: 'darkgray',
                        pointRadius: 0,
                        data: ubound,
                    },
                    {
                        label: 'Lower Bound',
                        fill: false,
                        lineTension: 0.1,
                        borderColor: 'darkgray',
                        pointRadius: 0,
                        data: lbound,
                    },
                ]
            }
            this.setState({
                footPointsHistory: data,
            });
        }).catch(error => {
            console.log(error);
        })
    }

    loadActiveFootpoints(pid, initial) {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/temperature/temp/footpoints/${pid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            // console.log(response.data.fields[0]);
            let activePoints = {
                right: [],
                left: []
            }
            const data = response.data.fields[0];
            if (data.r_great_toe) {
                activePoints.right.push(1)
            }
            if (data.r_metatarsal_1) {
                activePoints.right.push(2)
            }
            if (data.r_metatarsal_2_4) {
                activePoints.right.push(3)
            }
            if (data.r_metatarsal_5) {
                activePoints.right.push(4)
            }
            if (data.r_medial_plantar) {
                activePoints.right.push(5)
            }
            if (data.r_heel) {
                activePoints.right.push(6)
            }
            if (data.l_great_toe) {
                activePoints.left.push(1)
            }
            if (data.l_metatarsal_1) {
                activePoints.left.push(2)
            }
            if (data.l_metatarsal_2_4) {
                activePoints.left.push(3)
            }
            if (data.l_metatarsal_5) {
                activePoints.left.push(4)
            }
            if (data.l_medial_plantar) {
                activePoints.left.push(5)
            }
            if (data.l_heel) {
                activePoints.left.push(6)
            }
            const historyFoot = activePoints.right.length > 0 ? "Right" : (activePoints.left.length > 0 ? "Left" : null)
            const historyPoint = historyFoot ? activePoints[historyFoot.toLowerCase()][0] : null;
            this.setState({
                activePoints, historyFoot, historyPoint
            }, this.loadTempHistory);
        }).catch(error => {
            console.error(error);
        })
    }

    patientHasFoot(foot) {
        if (foot !== null && foot !== undefined && foot !== "None") {
            if (foot.toLowerCase() === 'left') {
                return this.state.activePoints.left.length > 0;
            }
            return this.state.activePoints.right.length > 0;
        }
        return false;
    }

    patientHasPoint(foot, point) {
        // console.log(foot, point)
        if (foot !== null && foot !== undefined && foot !== "None" && point != null && point !== undefined && point !== "None") {
            return this.state.activePoints[foot.toLowerCase()].includes(point);
        }
        return false;
    }

    loadTempHistory() {
        let authToken = cookies.get('auth_token')
        const point = Number.parseInt(this.state.historyPoint, 10) + (this.state.historyFoot === 'Left' ? 6 : 0);
        axios({
            method: 'get',
            url: `${base}/api/temperature/temp/temperatureHistory/${this.state.pid}/${point}/?days=${this.state.historyRange}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            const temperatures = response.data.temperatures.map((temperature) => {
                return temperature.Reading;
            });
            const dates = response.data.temperatures.map((temperature) => {
                return moment(temperature.LocalTimestamp.split('T')[0]).format('MM/DD/YYYY')
            });

            const data = {
                labels: dates.reverse(),
                datasets: [
                    {
                        label: 'Temperature (F)',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: '#22BF2A',
                        borderColor: 'black',
                        borderWidth: 1,
                        pointBackgroundColor: '#22BF2A',
                        pointBorderColor: 'black',
                        pointBorderWidth: 1,
                        data: temperatures.reverse(),
                    }
                ]
            };
            this.setState({ historyData: data });
        }).catch(error => {
            console.error(error);
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    handlePointFilter(filter) {
        this.setState({ pointFilter: filter },
            this.loadFootPoints
        )
    }

    getBarGraphLabel(graphData, def) {
        if (def === null || def === undefined) {
            def = 'Most Recent Day Foot Print Pairs';
        }
        if (graphData && graphData.date) {
            return graphData.date + ' Foot Print Pairs'
        }
        return def;
    }

    downloadRecord() {
        let authToken = cookies.get('auth_token')
        if (this.state.user.role === 'admin') {
            axios({
                method: 'get',
                url: `${base}/api/temperature/data/csv/?patient=${this.state.pid}&threshold=${this.state.threshold}&endDate=${this.state.endDate}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${authToken}`
                },
                responseType: 'blob',
            }).then((response) => {
                FileSaver(response.data, `patient_${this.state.patient.fields.PatientId}_${this.state.patient.fields.FirstName}_${this.state.patient.fields.LastName}_records_${this.state.threshold}_days_from_${this.state.endDate}.xlsx`)
            });
        } else {
            axios({
                method: 'get',
                url: `${base}/api/temperature/caregiver-data/csv/?patient=${this.state.pid}&threshold=${this.state.threshold}&endDate=${this.state.endDate}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${authToken}`
                },
                responseType: 'blob',
            }).then((response) => {
                FileSaver(response.data, `patient_${this.state.patient.fields.PatientId}_${this.state.patient.fields.FirstName}_${this.state.patient.fields.LastName}_records_${this.state.threshold}_days_from_${this.state.endDate}.xlsx`)
            });
        }
    }

    activatePatient() {
        let csrfToken = cookies.get('csrftoken');
        let authToken = cookies.get('auth_token')
        let patient = this.state.patient ? this.state.patient.fields : {};
        patient['Active'] = true;
        patient['EnrollmentDate'] = moment().format('YYYY-MM-DD h:m:s')
        axios({
            method: 'put',
            url: `${base}/api/patients/patient/${this.state.pid}/`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            data: patient,
        }).then((response) => {
            this.setState({
                patient: {fields: patient}
            });
        })
    }

    deactivatePatient() {
        let csrfToken = cookies.get('csrftoken');
        let authToken = cookies.get('auth_token')
        let patient = this.state.patient ? this.state.patient.fields : {};
        patient['Active'] = false;
        patient['EnrollmentDate'] = null
        axios({
            method: 'put',
            url: `${base}/api/patients/patient/${this.state.pid}/`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            data: patient,
        }).then((response) => {
            this.setState({
                patient: {fields: patient}
            });
        })
    }

    deletePatient() {
        this.setState({modalOpen: false})
        let csrfToken = cookies.get('csrftoken');
        let authToken = cookies.get('auth_token')
        axios({
            method: 'delete',
            url: `${base}/api/patients/patient/${this.state.pid}/`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
        }).then((response) => {
            this.props.history.push('/admin/patients/list')
        })
    }

    render() {
        let patient = this.state.patient ? this.state.patient.fields : {};
        let history = this.state.history ? this.state.history.fields : {};
        let screening = this.state.screening ? this.state.screening.fields : {};
        let vitals = this.state.vitals ? this.state.vitals.fields : {};
        let vid = this.state.vitals ? this.state.vitals.pk : undefined;
        let hid = this.state.history ? this.state.history.pk : undefined;
        let sid = this.state.screening ? this.state.screening.pk : undefined;

        let styling = {}
        styling['screening'] = {}
        let totalScore = screening.totalScore
        switch (true) {
            case (totalScore <= 4):
                styling.screening.color = 'green'
                break;
            case (totalScore <= 9):
                styling.screening.color = 'yellowgreen'
                break;
            case (totalScore <= 14):
                styling.screening.color = 'orange'
                break;
            case (totalScore <= 19):
                styling.screening.color = 'orangered'
                break;
            case (totalScore <= 27):
                styling.screening.color = 'red'
                break;
        }

        let flags = {
            heart: ((vitals.rightCalculated && (vitals.rightCalculated <= 0.9 || vitals.rightCalculated >= 1.02)
                || vitals.leftCalculated && (vitals.leftCalculated <= 0.9 || vitals.leftCalculated >= 1.02))
                && vitals.heart === "No")
        }
        return (
            <Fragment>
                <ExportGraph pid={this.state.pid} />
                {this.state.pageLoading ?
                    <Container></Container> :
                    <Container>
                        {
                            this.state.user.role === 'admin' && 
                            <Row>
                                <Col md="4">
                                    <Input type="number" name="threshold" id="threshold" value={this.state.threshold} onChange={this.handleInputChange} />  
                                    <label htmlFor="threshold">Days Back</label>
                                </Col>
                                <Col md="4">
                                    <Input type="date" name="endDate" id="endDate" value={this.state.endDate} onChange={this.handleInputChange} />  
                                    <label htmlFor="endDate">From</label>
                                </Col>
                                <Col md="4">
                                    <Button onClick={this.downloadRecord} variant="raised" color="primary">Download Patient Records</Button>
                                </Col>
                            </Row>
                        }
                        <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
                            <ModalBody>
                                This will permantely delete this patient. Are you sure you want proceed?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.deletePatient}>Yes</Button>{' '}
                                <Button color="accent" onClick={this.toggleModal}>No</Button>
                            </ModalFooter>
                        </Modal>`
                        <Card>
                            <CardHeader>
                                <h2 className="float-left">{patient.LastName + ', ' + patient.FirstName}</h2>
                                <Dropdown className="float-right" variant="raised" label="Update">
                                <DropdownItem link={`/admin/patients/${this.state.pid}/change-password`}>Reset Password</DropdownItem>
                                    <DropdownItem>
                                        <Link style={{ textDecoration: 'none' }} to={`footpoints`}>
                                            Configure Footpoints
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem><Link
                                        style={{ textDecoration: 'none' }}
                                        to={{
                                            pathname: `/admin/patients/${this.state.patient.pk}/letter/new`,
                                            state: {
                                                AtRisk: (vitals.rightThreshold >= 25 || vitals.leftThreshold >= 25), //TODO: Add Semmes
                                                Depression: totalScore >= 10,
                                                SelfHarm: screening.selfHarm >= 1,
                                                RestPain: history.legFootPain,
                                                ABI: flags["heart"]
                                            }
                                        }}>Generate Letter</Link></DropdownItem>
                                    <DropdownItem><Link style={{ textDecorationColor: 'none' }} to={`/admin/patients/${this.state.patient.pk}/letter/list/`}>
                                        Previous Letters
                                </Link></DropdownItem>
                                    <hr hidden={(!this.state.user || this.state.user.role !== 'caremgr')} />
                                    <DropdownItem hidden={(!this.state.user || this.state.user.role !== 'caremgr')} link={"/admin/update?" + qs.stringify({ pid: this.state.pid, then: `/admin/patients/${this.state.patient.pk}/view` })}>Enter Basic Info</DropdownItem>
                                    <DropdownItem hidden={(!this.state.user || this.state.user.role !== 'caremgr')} link={"/admin/vitals?" + qs.stringify({ pid: this.state.pid, vid: vid, then: `/admin/patients/${this.state.patient.pk}/view` })}>Enter Vitals</DropdownItem>
                                    <DropdownItem hidden={(!this.state.user || this.state.user.role !== 'caremgr')} link={"/admin/history?" + qs.stringify({ pid: this.state.pid, hid: hid, then: `/admin/patients/${this.state.patient.pk}/view` })}>Enter History</DropdownItem>
                                    <DropdownItem hidden={(!this.state.user || this.state.user.role !== 'caremgr')} link={"/admin/screening?" + qs.stringify({ pid: this.state.pid, sid: sid, then: `/admin/patients/${this.state.patient.pk}/view` })}>Enter Screening</DropdownItem>
                                </Dropdown>
                                {!patient.Active && <Fragment>
                                <Button variant="raised" className="float-right" color="danger" hidden={(!this.state.user || this.state.user.role !== 'caremgr')} onClick={this.toggleModal}>Remove Patient</Button>
                                <Button variant="raised" className="float-right" color="primary" hidden={(!this.state.user || this.state.user.role !== 'caremgr')} onClick={this.activatePatient}>Enroll Patient</Button>
                                </Fragment>}
                                {patient.Active && <Fragment>
                                <Button variant="raised" className="float-right" color="danger" hidden={(!this.state.user || this.state.user.role !== 'caremgr')} onClick={this.deactivatePatient}>Unenroll Patient</Button>
                                <a className="mui-btn mui-btn--raised float-right bg-success" href="#">Enrolled</a>
                                </Fragment>}
                            </CardHeader>
                            <CardBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === 'patient' ? 'active' : ''} onClick={() => { this.toggle('patient') }}>Basic Information</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === 'vitals' ? 'active' : ''} onClick={() => { this.toggle('vitals') }}>Patient Vitals</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === 'history' ? 'active' : ''} onClick={() => { this.toggle('history') }}>Patient History</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === 'screening' ? 'active' : ''} onClick={() => { this.toggle('screening') }}>Patient Screening</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === 'alerts' ? 'active' : ''} onClick={() => { this.toggle('alerts') }}>Patient Alerts</NavLink>
                                    </NavItem>
                                    {(this.state.role === 'admin' || this.state.role === "caremgr") && <NavItem>
                                        <NavLink className={this.state.activeTab === 'reports' ? 'active' : ''} onClick={() => { this.toggle('reports') }}>Patient Temperature Reports</NavLink>
                                    </NavItem> }
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="patient">
                                        <br />
                                        <div className="clearfix">
                                            <span className="float-right"><a style={{ color: '#2196F3' }} href={`/admin/patients/${this.state.patient.pk}/referral/new`}>Refer to Specialist</a></span>
                                        </div>
                                        <Row>
                                            <Col md="6">
                                                <div className="ml-4">
                                                    <p className="h4">Patient ID: {patient.PatientId}</p>
                                                    <p className="h4">Name: {patient.FirstName + ' ' + patient.LastName}</p>
                                                    <p className="h5">DOB: {patient.dob}</p>
                                                    <p className="h5">Address: {patient.Address}</p>
                                                    <p className="h5">Telephone Numbers:</p>
                                                    <div className="ml-4">
                                                        <p className="h6">Home: {patient.HomePhone}</p>
                                                        <p className="h6">Cell: {patient.CellPhone}</p>
                                                        <p className="h6">Work: {patient.WorkPhone}</p>
                                                    </div>
                                                    <p className="h5">Email: {patient.PrimaryEmail}</p>
                                                    <p className="h5">Significant Other: {patient.EmergencyContact}</p>
                                                    <p className="h5">Insurance Information: {patient.Insurance}</p>
                                                    <p className="h5">Primary Care Physician: {patient.PrimaryCarePhysician}</p>
                                                    <p className="h5">Referral Source: {patient.ReferralSource}</p>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                {this.state.referrals.map((item, idx) => (
                                                    <Card key={idx} className="border-warning">
                                                        <CardBody>
                                                            Care manager {item.Personnel__LastName}, {item.Personnel__FirstName} referred patient
                                                        &nbsp;{item.Patient__LastName}, {item.Patient__FirstName}
                                                            &nbsp;to {item.Type} {item.Specialist__LastName}, {item.Specialist__FirstName}
                                                            &nbsp;on {item.Time__month}/{item.Time__day}/{item.Time__year}
                                                        </CardBody>
                                                    </Card>
                                                ))}
                                            </Col>
                                        </Row>
                                        <br />
                                    </TabPane>
                                    <TabPane tabId="vitals">
                                        <br />
                                        {!this.state.vitals && (
                                            <div>
                                                <p className="h4">
                                                    NO VITALS TAKEN
                                            </p>
                                            </div>
                                        )}
                                        {this.state.vitals && (
                                            <div className="clearfix">
                                                <span className="h6 float-right mb-0">Last Updated: {vitals.updated}</span>
                                                <br />
                                                <span className="float-right"><a style={{ color: '#2196F3' }} href={"/admin/vitals?" + qs.stringify({ vid: vid, then: `/admin/patients/${this.state.patient.pk}/view` })}>Enter Test Results</a></span>
                                            </div>)}
                                        {this.state.vitals && (
                                            <div className="mx-4">
                                                <Row>
                                                    <Col md="6">
                                                        <p className="h4">Height and Weight</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Height: {vitals.height}</p>
                                                            <p className="h5">Weight: {vitals.weight}</p>
                                                        </div>
                                                        <p className="h4">Blood Pressure</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Right Side: {vitals.rightSystolic + '/' + vitals.rightDiastolic}</p>
                                                            <p className="h5">Left Side: {vitals.leftSystolic + '/' + vitals.leftDiastolic}</p>
                                                        </div>
                                                        <p className="h4">Ankle Brachial Index</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Right Side: {vitals.rightCalculated}</p>
                                                            <p className="h5">Left Side: {vitals.leftCalculated}</p>
                                                        </div>
                                                        <p className="h4" style={{ color: (vitals.rightThreshold >= 25 || vitals.leftThreshold >= 25) ? "red" : null }}>Biothesiometer
                                                    {(vitals.rightThreshold >= 25 || vitals.leftThreshold >= 25) &&
                                                                <span className="ml-3 fa fa-exclamation-circle" id="biothesiometer-warning">
                                                                    <UncontrolledTooltip placement="top" target="biothesiometer-warning">
                                                                        AT RISK: =&gt; 25 volts. Recommended to enroll in home technology program.
                                                        </UncontrolledTooltip>
                                                                </span>}
                                                        </p>
                                                        <div className="ml-4">
                                                            <p className="h5" style={{ color: (vitals.rightThreshold >= 25) ? "red" : null }}>Right Side: {vitals.rightThreshold}</p>
                                                            <p className="h5" style={{ color: (vitals.leftThreshold >= 25) ? "red" : null }}>Left Side: {vitals.leftThreshold}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <p className="h4">History and Medication Assesment</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Notes: {vitals.history}</p>
                                                            <p className="h5" style={{ color: flags["heart"] ? "red" : null }}>Patient on Avoidant Heart Medication: {vitals.heart}
                                                                {flags["heart"] &&
                                                                    <span className="ml-3 fa fa-exclamation-circle" id="heart-warning">
                                                                        <UncontrolledTooltip placement="bottom" target="heart-warning">
                                                                            Patient ABI &gt;= 1.02 or &lt;=0.9 and is not on Avoidant Heart Medication.
                                                                </UncontrolledTooltip>
                                                                    </span>
                                                                }
                                                            </p>
                                                        </div>
                                                        <p className="h4">Pedorthist Sizing</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Notes: {vitals.pedorthist}</p>
                                                        </div>
                                                        <p className="h4">Lab Work Results</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Hemoglobin A1C: {vitals.a1c}</p>
                                                            <p className="h5">Creatinine: {vitals.creatinine}</p>
                                                            <p className="h5">LDL: {vitals.ldl}</p>
                                                            <p className="h5">HDL: {vitals.hdl}</p>
                                                        </div>
                                                        <p className="h4">Retinopathy Examination Results</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Left Eye: {vitals.eyeLeft}</p>
                                                            <p className="h5">Right Eye: {vitals.eyeRight}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <p className="h4">Semmes/Weinstein Monofilaments</p>
                                                <div className="ml-4">
                                                    <Row>
                                                        <Col md="6">
                                                            <p className="h5">Right Foot - Dorsal 1: {vitals.rightFootDorsal1}</p>
                                                            <p className="h5">Right Foot - Dorsal 2: {vitals.rightFootDorsal2}</p>
                                                            <p className="h5">Right Foot - Plantar 1: {vitals.rightFootPlantar1}</p>
                                                            <p className="h5">Right Foot - Plantar 2: {vitals.rightFootPlantar2}</p>
                                                            <p className="h5">Right Foot - Plantar 3: {vitals.rightFootPlantar3}</p>
                                                            <p className="h5">Right Foot - Plantar 4: {vitals.rightFootPlantar4}</p>
                                                            <p className="h5">Right Foot - Plantar 5: {vitals.rightFootPlantar5}</p>
                                                            <p className="h5">Right Foot - Plantar 6: {vitals.rightFootPlantar6}</p>
                                                        </Col>
                                                        <Col md="6">
                                                            <p className="h5">Left Foot - Dorsal 1: {vitals.leftFootDorsal1}</p>
                                                            <p className="h5">Left Foot - Dorsal 2: {vitals.leftFootDorsal2}</p>
                                                            <p className="h5">Left Foot - Plantar 1: {vitals.leftFootPlantar1}</p>
                                                            <p className="h5">Left Foot - Plantar 2: {vitals.leftFootPlantar2}</p>
                                                            <p className="h5">Left Foot - Plantar 3: {vitals.leftFootPlantar3}</p>
                                                            <p className="h5">Left Foot - Plantar 4: {vitals.leftFootPlantar4}</p>
                                                            <p className="h5">Left Foot - Plantar 5: {vitals.leftFootPlantar5}</p>
                                                            <p className="h5">Left Foot - Plantar 6: {vitals.leftFootPlantar6}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>)}
                                        {this.state.vitals && (<a href={`/admin/patients/${this.state.pid}/vitals/list`}>See Past Vitals</a>)}
                                        <br />
                                    </TabPane>
                                    <TabPane tabId="history">
                                        <br />
                                        {!this.state.history && (
                                            <div>
                                                <p className="h4">
                                                    NO HISTORY TAKEN
                                            </p>
                                            </div>
                                        )}
                                        {this.state.history && (
                                            <div className="clearfix">
                                                <span className="h6 float-right">Last Updated: {history.updated}</span>
                                            </div>)}
                                        {this.state.history && (
                                            <div className="mx-4">
                                                <Row>
                                                    <Col md="6">
                                                        <p className="h4">Diagnosis and Control</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Age at Diagnosis: {history.diagnosed}</p>
                                                            <p className="h5">Control Method: {history.controlMethod}</p>
                                                        </div>
                                                        <p className="h4">Complications</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Leg/Foot Ulcer or Lower Leg Amputation/Surgery: {history.ulcerAmputation}</p>
                                                            <p className="h5">Angioplasty, Stent or Leg Bypass Surgery: {history.circulation}</p>
                                                            <p className="h5">Foot Wound: {history.wound}</p>
                                                            <p className="h5">Smoking: {history.smoking}</p>
                                                        </div>
                                                        <p className="h4">Comorbidities</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Clinical Depression: {history.depression}</p>
                                                            <p className="h5">Problems with Eyes: {history.eyeProblems}</p>
                                                            <p className="h5">Heart Disease: {history.heartDisease}</p>
                                                            <p className="h5">Kidney Disease: {history.kidneyDisease}</p>
                                                            <p className="h5">High Blood Pressure: {history.highBloodPressure}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <p className="h4">Neuropathy</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Burning or Tingling in Legs and Feet: {history.legFootBurning}</p>
                                                            <p className="h5">Leg or Foot Pain with Activity or Rest: {history.legFootPain}</p>
                                                            <p className="h5">Changes in Skin Color or Skin Lesions: {history.skinColorLesions}</p>
                                                            <p className="h5">Loss of Sensation in Legs and Feet: {history.legFootNumbness}</p>
                                                        </div>
                                                        <p className="h4">Procedures and Medications</p>
                                                        <div className="ml-4">
                                                            <p className="h5">Surgeries: {history.surgeries}</p>
                                                            <p className="h5">Medications: {history.medications}</p>
                                                            <p className="h5">Most Recent Hemoglobin A1C: {history.recentA1c}</p>
                                                            <p className="h5">Morning Glucose: {history.morningGlucose}</p>
                                                            <p className="h5">Regular Podiatric Care: {history.podiatricCare}</p>
                                                        </div>
                                                        <p className="h4">Additional Notes</p>
                                                        <div className="ml-4">
                                                            <p className="h5">{history.additionalNotes}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>)}
                                        {this.state.history && (<a href={`/admin/patients/${this.state.pid}/history/list`}>See Past History</a>)}
                                        <br />
                                    </TabPane>
                                    <TabPane tabId="screening">
                                        <br />
                                        {!this.state.screening && (
                                            <div>
                                                <p className="h4">
                                                    NO SCREENING TAKEN
                                            </p>
                                            </div>
                                        )}
                                        {this.state.screening && (
                                            <div className="clearfix">
                                                <span className="h6 float-right">Last Updated: {screening.updated}</span>
                                            </div>)}
                                        {this.state.screening && (
                                            <div className="m-4">
                                                <p className="h4">Over the Past 2 Weeks how often have you had these Symptoms:</p>
                                                <div className="ml-4">
                                                    <p className="h5">Little interest or pleasure in doing things: {screening.lostInterest}</p>
                                                    <p className="h5">Feeling down, depressed or hopeless: {screening.depressed}</p>
                                                    <p className="h5">Trouble falling or staying asleep, or sleeping too much: {screening.troubleSleeping}</p>
                                                    <p className="h5">Feeling tired or having little energy: {screening.noEnergy}</p>
                                                    <p className="h5">Poor appetite or overeating: {screening.troubleEating}</p>
                                                    <p className="h5">Feeling bad about yourself, or that you are a failure, or have let yourself or your family down: {screening.poorSelfImage}</p>
                                                    <p className="h5">Trouble concentrating on things, such as reading the newspaper or watching television: {screening.noConcentration}</p>
                                                    <p className="h5">Moving or speaking so slowly that other people could have noticed? Or the opposite, being so fidgety or restless that you have been moving around a lot more than usual: {screening.listlessAgitated}</p>
                                                    <p className="h5" style={{ color: screening.selfHarm >= 1 ? 'red' : '' }}>Thoughts that you would be better off dead or of hurting yourself in some way:
                                                <span>{screening.selfHarm} {screening.selfHarm >= 1 && <i id="selfharm-warning" className="fa fa-exclamation-circle" ></i>}
                                                        </span>
                                                        {screening.selfHarm >= 1 &&
                                                            <UncontrolledTooltip placement="top" target="selfharm-warning">
                                                                RECOMMENDED: Immediate referral.
                                                </UncontrolledTooltip>
                                                        }
                                                    </p>                                        </div>
                                                <p className="h4" style={styling['screening']}>Patient health questionnaire 9 question total score: {screening.totalScore}</p>
                                                <p>
                                                    <small>
                                                        <span style={{ color: 'green' }}>0-4: Minimal or None</span>
                                                        <br />
                                                        <span style={{ color: 'yellowgreen' }}>5-9: Mild</span>
                                                        <br />
                                                        <span style={{ color: 'orange' }}>10-14: Moderate</span>
                                                        <br />
                                                        <span style={{ color: 'orangered' }}>15-19: Moderately severe</span>
                                                        <br />
                                                        <span style={{ color: 'red' }}>20-27: Severe</span>
                                                    </small>
                                                </p>
                                            </div>)}
                                        {this.state.screening && (<a href={`/admin/patients/${this.state.pid}/screening/list`}>See Past Screenings</a>)}
                                        <br />
                                    </TabPane>
                                    <TabPane tabId="alerts">
                                        <br />
                                        {this.state.alerts.map((alert) => (
                                            <Card key={alert.pk}>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <p className="h4">{`${alert.Text} on ${alert.reoccur ? alert.reoccur : alert.date}`}</p>
                                                        </Col>
                                                        <Col>
                                                            {alert.Resolution == 'No resolution' ?
                                                                <p style={{ color: 'red' }} className="h4 float-right">{`${alert.Resolution}`}</p> :
                                                                <p className="h4 float-right">{`${alert.Resolution}`}</p>}
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        ))}
                                    </TabPane>
                                    {(this.state.role === 'admin' || this.state.role === "caremgr") && <TabPane tabId="reports">
                                        <br />
                                        <Row>
                                            <Col md="6">
                                                <p className="h4">Contralateral Comparison</p>
                                                <Divider />
                                                {
                                                    this.state.colateralPoints1 ? (
                                                        <div>
                                                            <h6>{this.getBarGraphLabel(this.state.colateralPoints1)}{this.state.isRetest1 && <span style={{ color: 'red' }}>&nbsp;Retest</span>}</h6>
                                                            <Divider />
                                                            <Bar data={this.state.colateralPoints1} options={{ scales: { yAxes: [{ ticks: { suggestedMin: 60, suggestedMax: 90 } }], xAxes: [{ ticks: { autoSkip: false } }] } }} height={200} />
                                                        </div>
                                                    ) : (
                                                            <h6>No data available</h6>
                                                        )
                                                }
                                                {
                                                    this.state.colateralPoints2 ? (
                                                        <div>
                                                            <h6>{this.getBarGraphLabel(this.state.colateralPoints2)}{this.state.isRetest2 && <span style={{ color: 'red' }}>&nbsp;Retest</span>}</h6>
                                                            <Divider />
                                                            <Bar data={this.state.colateralPoints2} options={{ scales: { yAxes: [{ ticks: { suggestedMin: 60, suggestedMax: 90 } }], xAxes: [{ ticks: { autoSkip: false } }] } }} height={200} />
                                                        </div>
                                                    ) : (
                                                            this.state.colateralPoints1 && <h6>No more data available</h6>
                                                        )
                                                }
                                                {
                                                    this.state.colateralPoints3 ? (
                                                        <div>
                                                            <h6>{this.getBarGraphLabel(this.state.colateralPoints3)}{this.state.isRetest3 && <span style={{ color: 'red' }}>&nbsp;Retest</span>}</h6>
                                                            <Divider />
                                                            <Bar data={this.state.colateralPoints3} options={{ scales: { yAxes: [{ ticks: { suggestedMin: 60, suggestedMax: 90 } }], xAxes: [{ ticks: { autoSkip: false } }] } }} height={200} />
                                                        </div>
                                                    ) : (
                                                            this.state.colateralPoints2 && <h6>No more data available</h6>
                                                        )
                                                }
                                            </Col>
                                            <Col md="6">
                                                <p className="h4">Foot Points Trending</p>
                                                <Row>
                                                    <h6>Foot point differentials</h6>
                                                    <Line data={this.state.footPointsHistory}
                                                        height={200}
                                                        options={{
                                                            legend: { display: false },
                                                            tooltips: { callbacks: { title: (tooltipItem, data) => { return data.labels[tooltipItem[0].index] || ''; } } },
                                                            scales: {
                                                                yAxes: [
                                                                    {
                                                                        ticks: {
                                                                            suggestedMin: -7,
                                                                            suggestedMax: 7,
                                                                            stepSize: 1,
                                                                            callback: (label, index, labels) => {
                                                                                switch (label) {
                                                                                    case labels[labels.length - 1]:
                                                                                        return 'Left';
                                                                                    case labels[0]:
                                                                                        return 'Right';
                                                                                    default:
                                                                                        return label;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                ],
                                                                xAxes: [
                                                                    {
                                                                        ticks: {
                                                                            autoSkip: false,
                                                                            callback: (label, index, labels) => {
                                                                                let date = label.split('-')[0];
                                                                                let idx = index % 6;
                                                                                if (idx === 0 && date !== 'extend') {
                                                                                    return date;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }} />

                                                    <Row>
                                                    {(this.state.activePoints.left.length <= 0 && this.state.activePoints.right.length <= 0) && <Link className="mui-btn mui-btn--raised float-left" to={"/admin/patients/" + this.state.pid + "/footpoints"}>Configure footpoints</Link>}
                                                    </Row>
                                                    <Row>
                                                        <br />
                                                        <Button onClick={() => this.handlePointFilter(7)}>7 Days</Button>
                                                        <Button onClick={() => this.handlePointFilter(15)}>15 Days</Button>
                                                        <Button onClick={() => this.handlePointFilter(30)}>30 Days</Button>
                                                        <Button onClick={() => this.handlePointFilter(100)}>100 Days</Button>
                                                        <br />
                                                        <Input type="number" className="w-50" name="customFilter" id="graph-custom-filter" maxLength={50} value={this.state.customFilter} onChange={this.handleInputChange} />
                                                        <Button onClick={() => this.handlePointFilter(this.state.customFilter)}>Custom Days</Button>
                                                    </Row>
                                                </Row>
                                                <Divider />
                                                <Row>
                                                    <h6>Foot point temperatures over time</h6>
                                                    <Row>
                                                        <Col>
                                                            <Select label="Foot" name="historyFoot" value={this.state.historyFoot} onChange={this.updateHistory}>
                                                                <Option label="None"></Option>
                                                                {this.patientHasFoot('right', 1) && <Option value="Right" label="Right Foot"></Option>}
                                                                {this.patientHasFoot('left', 1) && <Option value="Left" label="Left Foot"></Option>}
                                                            </Select>
                                                        </Col>
                                                        <Col>
                                                            <Select label="Area" name="historyPoint" value={this.state.historyPoint} onChange={this.updateHistory}>
                                                                <Option label="None"></Option>
                                                                {this.patientHasPoint(this.state.historyFoot, 1) && <Option value={1} label="1 - Great Toe"></Option>}
                                                                {this.patientHasPoint(this.state.historyFoot, 2) && <Option value={2} label="2 - Metatarsal 1"></Option>}
                                                                {this.patientHasPoint(this.state.historyFoot, 3) && <Option value={3} label="3 - Metatarsal 2 - 4"></Option>}
                                                                {this.patientHasPoint(this.state.historyFoot, 4) && <Option value={4} label="4 - Metatarsal 5"></Option>}
                                                                {this.patientHasPoint(this.state.historyFoot, 5) && <Option value={5} label="5 - Medial Plantar"></Option>}
                                                                {this.patientHasPoint(this.state.historyFoot, 6) && <Option value={6} label="6 - Heel"></Option>}
                                                            </Select>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Input label="Days" name="historyRange" type="number" min="1" max="14" value={this.state.historyRange} onChange={this.updateHistory}></Input>
                                                        </Col>
                                                    </Row>
                                                    <Line data={this.state.historyData}
                                                        height={200}
                                                        options={{ scales: { yAxes: [{ ticks: { suggestedMin: 60, suggestedMax: 90 } }], xAxes: [{ ticks: { autoSkip: false } }] } }} />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane> }
                                </TabContent>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Container>}
            </Fragment>
        )
    }
}
